import React, { Component } from "react";

class About extends Component{
    render(){
        let title = 'O mně',
        description = 'Jsem 23letý vášnivý grafik. Videím, grafice a animacím se věnuji již 6 let Mám za sebou střední školu obor IT. Právě díky tomu mám široký rozhled v mutlimediální tvorbě. Třetím rokem působím v marketingu, mám za sebou úspěšnou volební kampaň a spolupráci s mezinárodní frančízou. Líbí se vám má práce? Neváhejte mě kontaktovat!';
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                <video src="assets/video/main-video.mp4" autoPlay muted loop></video>
                                </div>
                            </div> 

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                        <img src="assets/images/about/sign.png" alt=""/>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Who we are</h3>
                                                <p>There are many vtions of passages of Lorem Ipsum available, but the majority have suffered.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About;